<template>
  <div>
    <Header />
    <div class="container card">
      <h1>Add Project</h1>
      <div class="add">
        <form @submit.prevent="addProject">
          <div class="form-group">
            <label for="role">Product:</label>
            <select class="form-control rounded" id="prod_id" name="prod_id" v-model="newProject.prod_id">
              <option value=""></option>
              <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="start_date">Start Date:</label>
            <input type="date" class="form-control rounded" id="start_date" name="start_date" placeholder="YYYY-MM-DD"
              v-model="newProject.start_date" />
          </div>


          <div class="form-group">
            <label for="end_date">End Date:</label>
            <input type="date" class="form-control rounded" id="end_date" name="end_date" placeholder="YYYY-MM-DD"
              v-model="newProject.end_date" />
          </div>


          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Project Name"
              v-model="newProject.name" />
          </div>
          <!-- <div class="form-group">
            <label for="role">Instructor:</label>
            <select class="form-control rounded" id="user_id" name="user_id" v-model="selectedInstructors[0]">
              <option value=""></option>
              <option v-for="instructor in instructors" :key="instructor.user_id" :value="instructor.user_id">ID: {{
                instructor.user_id }} &nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp; {{ instructor.first_name + " " +
    instructor.last_name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="instructor2">Second Instructor: <br> (Optional)</label>
            <select class="form-control rounded" id="instructor2" name="instructor2" v-model="selectedInstructors[1]">
              <option value=""></option>
              <option v-for="instructor in instructors" :key="instructor.user_id" :value="instructor.user_id">ID: {{
                instructor.user_id }} &nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp; {{ instructor.first_name + " " +
    instructor.last_name }}</option>
            </select>
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          </div> -->
          <div> 
            <button class="btn btn-primary rounded" type="submit">Add Project</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  
  
<script>
import axios from 'axios';
import Header from '../Header.vue';

export default {
  name: 'AddProject',
  components: {
    Header
  },
  data() {
    return {
      instructors: [],
      products: [],
      projects: [],
      newProject: {
        name: '',
        start_date: '',
        end_date: '',
        prod_id: '',
        product_name: '',
        user_id: '',

      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      loggedInUser: '',
      selectedInstructors: [null, null],
    };
  },
  watch: {
    'newProject.prod_id': function (newProductId) {
      if (newProductId && this.products) {
        const selectedProduct = this.products.find(product => product.product_id === newProductId);
        if (selectedProduct) {
          this.newProject.name = selectedProduct.name;
        }
      }
    }
  },
  methods: {
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },

    async addProject() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          '/api/projects',
          {
            name: this.newProject.name,
            start_date: this.newProject.start_date,
            end_date: this.newProject.end_date,
            prod_id: this.newProject.prod_id,
            product_name: this.newProject.product_name,
            instructor_id: this.selectedInstructors[0],
            instructor2_id: this.selectedInstructors[1],
          },
          { headers }
        );

        if (response.status === 201) {
          window.alert('Project added Successfully');
          this.newProject = {
            name: '',
            start_date: '',
            end_date: '',
            prod_id: '',
            product_name: '',
            instructor_id: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchProjects();
        this.$router.push({ name: 'ViewProjects' });
      } catch (error) {
        console.error('Error adding Project:', error);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors

          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },

    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/projects', { headers });

        if (response.status === 200) {
          this.projects = response.data.projects;
        }
      } catch (error) {
        console.error('Error retrieving projects:', error);
        // Handle error
      }
    },
    async fetchProducts() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/products', { headers });

        if (response.status === 200) {
          this.products = response.data; // Populate the products array with API data
        }
      } catch (error) {
        console.error('Error retrieving products:', error);
        // Handle error
      }
    },
    async fetchInstructors() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });

        if (response.status === 200) {
          // Filter the response data to include only users with the role 'instructor'
          this.instructors = response.data.filter(user => user.role === 'instructor');
        }
      } catch (error) {
        console.error('Error retrieving instructors:', error);
        // Handle error
      }
    },

  },
  async mounted() {
    await this.fetchInstructors();
    await this.fetchProjects();
    this.fetchProducts();

  },
};
</script>
  
<style scoped>
.container.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 10px;
  min-width: 90%;
  min-height: 90%;
}

.add button {
  width: 200px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 200px;
  display: inline-block;
  margin-bottom: 35px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
  font-size: 18px;

}

.form-control {
  border-radius: 5px;
}

.form-control#start_date,
.form-control#end_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 200px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 50px;
}
</style>