<template>
  <Header />
  <div class="heading">
    <h1>Assign Assessors to Project ID: {{ $route.params.project_id }}</h1>
  </div>

  <div class="search-container">
    <input type="text" v-model="searchQuery" placeholder="Search by Name" />
  </div>

  <div class="pagination-button-container">
    <div class="pagination-container">
      <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
    </div>
    <div class="pagination-info">
      Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredAssessorsLength }} Assessors
    </div>
  </div>

  <div class="container-fluid">
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th @click="sortBy('user_id')">
            Assessor ID
            <span v-if="sortField === 'user_id'" v-html="getFieldArrow()"></span>
          </th>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Assign Assessor</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredAssessors" :key="item.user_id">
          <td>{{ item.user_id }}</td>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>
            <input type="checkbox" v-model="selectedAssessors" :value="item.user_id">
          </td>
        </tr>
      </tbody>
    </table>
    <span v-if="filteredAssessors.length === 0">No Matching Assessors</span>

  </div>
    <button class="back-button" @click="goBack">Back</button>
    <button class="btn btn-primary rounded" @click="assignSelectedAssessors">Assign Assessors</button>

</template>
    
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';

export default {
  name: 'AssignAssessors',
  data() {
    return {
      assessors: [],
      selectedAssessors: [],
      sortField: 'user_id',
      sortDirection: 'asc',
      searchQuery: '',
      projectSearchQuery: '',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      newProject: {
        name: '',
        course_id: '',
        course_name: '',
        user_id: '',
      },
      filteredAssessorsLength: 0,
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
      }
    },
  },
  components: {
    Pagination,
    Header
  },
  async mounted() {
  try {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const project_id = this.$route.params.project_id;

    // Fetch the assessors assigned to the project
    const response = await axios.get(`/api/projects/${project_id}/users`, { headers });
    const assignedAssessors = response.data;

    // Now, you have the assigned assessors in the 'assignedAssessors' variable.
    console.log(assignedAssessors);

    const assignedAssessorIds = assignedAssessors.map(assessor => assessor.user_id);

    // Fetch all assessors (including those assigned to the project)
    const result = await axios.get('/api/users', { headers });

    if (result.status === 200) {
      // Filter the response data to include only users with the role 'assessor'
      this.assessors = result.data.filter(user => user.role === 'assessor');

      this.assessors = this.assessors.filter(assessor => !assignedAssessorIds.includes(assessor.user_id));

    }

    this.totalPages = Math.ceil(this.assessors.length / this.perPage);

  } catch (error) {
    console.error('An error occurred while fetching data:', error);
  }
},
  methods: {
    editAssessor(user_id) {
      window.location.href = `/assessors/${user_id}`;
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.assessors.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    getFieldArrow(field = 'user_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    assignSelectedAssessors() {
      if (this.selectedAssessors.length === 0) {
        return;
      }

      const project_id = this.$route.params.project_id;
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

  // Fetch the current assessors assigned to the course
  axios.get(`/api/projects/${project_id}/users`, { headers })
    .then(response => {
      const existingAssessors = response.data.map(assessor => assessor.user_id);

          // Filter out assessors already assigned to the course
          const newAssessors = this.selectedAssessors.filter(assessorId => !existingAssessors.includes(assessorId));


          console.log(newAssessors);

if (newAssessors.length === 0) {
  window.alert('All selected assessors are already in the project.');
  this.selectedAssessors = [];
  return;
}
      // Send a POST request to assign the remaining assessors to the course
      const data = {
        user_ids: newAssessors,
        role: 'assessor'
      };

      axios.post(`/api/projects/${project_id}/add-users`, data, { headers })
        .then(response => {
          console.log(response);
          window.alert('Assessors added successfully');
          this.selectedAssessors = [];
          this.$router.push({ name: 'ViewAssessors'});
        })
        .catch(error => {
          console.error('Error assigning assessors:', error);
          // Handle errors, e.g., display an error message.
        });
    })
    .catch(error => {
      console.error('Error fetching current assessors:', error);
      // Handle errors, e.g., display an error message.
    });
},
goBack() {
      this.$router.push({ name: 'ViewAssessors'});
    },
    calculateTotalPages() {
      this.computeFilteredAssessors();
      this.totalPages = Math.ceil(this.filteredAssessorsLength / this.perPage);
    },
    computeFilteredAssessors() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;

      const filteredAssessors = this.assessors.filter((assessor) => {
        const fullName = (assessor.first_name + assessor.last_name || "").toLowerCase(); // Fallback to empty string if null or undefined
        const assessorMatch = fullName.includes(this.searchQuery.toLowerCase()) || this.searchQuery === '';

        return assessorMatch
      });

      console.log(filteredAssessors);
      this.filteredAssessorsLength = filteredAssessors.length;
      console.log(this.filteredAssessorsLength);
      return filteredAssessors.slice(startIndex, endIndex);
  },
},
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredAssessorsLength ? end : this.filteredAssessorsLength;
    },
    filteredAssessors() {
      return this.computeFilteredAssessors();
    },
  },
};
</script>
    
    
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.pagination-container {
    margin-top: 55px;
    display: flex;
    margin-left: 3%;
}

.container-fluid {
  padding-top: 25px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.search-container input[type="text"] {
  margin-left: 40px;
  padding: 10px;
  font-size: 16px;
  width: 30%;
}

button.btn {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0%;
  margin-bottom: 15px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
  margin-right: 30px;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.heading h1 {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 3%;

}

.back-button {
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  float: left;
  margin-left: 30px;
  border: 2px solid black;
  padding: 3px;
}

button:hover {
  background-color: #7c927a;
  transition: transform 0.2s, box-shadow 0.2s;
}
.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}

.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 300px;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}
</style>